const translations = {
    success: {
        loaded: 'Cargado correctamente',
        updated: 'Datos actualizados',
        copied: 'Copiado al portapapeles'
    },
    error: {
        out: 'Fuera de contexto TakeBlip',
        update: 'Error al actualizar',
        validation: 'Datos inválidos'
    },
    warning: {},
    info: {}
};

export default translations;
