import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import RoutesPath from '../constants/routes-path';

import SetupPage from '../pages/Setup';
import HomePage from '../pages/Home';
import ExamplePage from '../pages/Example';

import Analytics from './Analytics';

const Routes = () => (
    <BrowserRouter>
        <Analytics>
            <SetupPage />
        </Analytics>
    </BrowserRouter>
);

export default Routes;
