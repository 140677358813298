import React from 'react';
import { useTranslation } from 'react-i18next';

import Card from '../../../../components/Card';

export const BlipData = ({ appInfo }) => {
    const { t } = useTranslation();
    return (        
        <Card className="min-h-18 pa3">
            <div className="overflow-auto">
                <h4 className="f4 mt0 mb3">
                    {t('system.title')}
                </h4>
                <table className="f6 w-100 mw8 center">
                    <tbody className="lh-copy">
                        <tr>
                            <td className="pv3 b bb b--black-20">
                                {t('system.tenant')}
                            </td>
                            <td className="pv3 bb b--black-20">
                                {appInfo.tenantId}
                            </td>
                        </tr>
                        <tr>
                            <td className="pv3 b bb b--black-20">
                                {t('system.name')}
                            </td>
                            <td className="pv3 bb b--black-20">
                                {appInfo.name}
                            </td>
                        </tr>
                        <tr>
                            <td className="pv3 b bb b--black-20">
                                {t('system.identifier')}
                            </td>
                            <td className="pv3 bb b--black-20">
                                {appInfo.shortName}
                            </td>
                        </tr>
                        <tr>
                            <td className="pv3 b bb b--black-20">
                                {t('system.accessKey')}
                            </td>
                            <td className="pv3 bb b--black-20">
                                {appInfo.authorization}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Card>    
    );
};

BlipData.propTypes = {
};

export default BlipData;
