const translations = {
    success: {
        loaded: 'Successfully loaded',
        updated: 'Data updated Successfully',
        copied: 'Copied to clipboard'
    },
    error: {
        out: 'Out of TakeBlip context',
        update: 'Failed to update',
        validation: 'Invalid data'
    },
    warning: {},
    info: {}
};

export default translations;