import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';

const Input = ({
    name,
    label,
    placeholder,
    icon,
    value,
    helperMessage,
    errorMessage,
    minLength = null,
    maxLength = null,
    danger = false,
    focused = false,
    waitTime = 500,
    textareaCols = 0,
    textareaRows = 0,
    isTextarea = false,
    errors = {},
    touched = {},
    onFocus = () => {},
    onBlur = () => {},
    onChange = () => {}
}) => {
    const elementRef = useRef(null);
    const [error, setError] = useState('');

    useEffect(() => {
        if (!elementRef.current) {
            return;
        }
        elementRef.current.value = value || '';
    }, [elementRef, value]);

    useEffect(() => {
        if (!elementRef.current) {
            return;
        }

        const _onChange = (evt) => {onChange(evt);};
        const _onFocus = (evt) => {onFocus(evt);};
        const _onBlur = (evt) => {onBlur(evt);};

        elementRef.current.addEventListener('bdsChange', _onChange);
        elementRef.current.addEventListener('bdsFocus', _onFocus);
        elementRef.current.addEventListener('bdsOnBlur', _onBlur);

        return () => {
            elementRef.current.removeEventListener('bdsChange', _onChange);
            elementRef.current.removeEventListener('bdsFocus', _onFocus);
            elementRef.current.removeEventListener('bdsOnBlur', _onBlur);
        };
        // eslint-disable-next-line
	}, []);

    useEffect(() => {
        const { current } = elementRef;
        if (!!focused && !!current) {
            current.setFocus();
        }
    }, [focused]);

    useEffect(() => {
        setError(
            getIn(touched, name) && getIn(errors, name)
                ? getIn(errors, name)
                : ''
        );
    }, [touched, errors, name]);

    return (
        <div className="relative">
            <bds-input
                data-testid="bds-input"
                ref={elementRef}
                input-name={name}
                label={label}
                placeholder={placeholder}
                icon={icon}
                value={value}
                helper-message={helperMessage}
                danger={!!error || danger}
                error-message={error || errorMessage}
                minlength={minLength}
                maxlength={maxLength}
                cols={textareaCols}
                rows={textareaRows}
                is-textarea={isTextarea}
            />
        </div>
    );
};

Input.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    icon: PropTypes.string,
    value: PropTypes.string,
    helperMessage: PropTypes.string,
    errorMessage: PropTypes.string,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    danger: PropTypes.bool,
    focused: PropTypes.bool,
    waitTime: PropTypes.number,
    textareaCols: PropTypes.number,
    textareaRows: PropTypes.number,
    isTextarea: PropTypes.bool,
    errors: PropTypes.object,
    touched: PropTypes.object,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func
};

export default Input;
