import { IframeMessageProxy } from 'iframe-message-proxy';
import IMPContainer from '../constants/iframe-message-proxy-container';

const getAuthorization = (application) => {
    const originalKey = atob(application.accessKey);
    const userAndKey = `${application.shortName}:${originalKey}`;
    const authorization = `Key ${btoa(userAndKey)}`;
    return authorization;
};

const getApplicationDataAsync = async (fullIdentity = null) => {
    const { response: application } = await IframeMessageProxy.sendMessage({
        action: IMPContainer.Actions.GET_APPLICATION,
        content: fullIdentity
    });
    application.authorization = getAuthorization(application);
    return application;
};

const getCurrentLanguageAsync = async () => {
    const { response } = await IframeMessageProxy.sendMessage({
        action: IMPContainer.Actions.GET_CURRENT_LANGUAGE
    });

    return response;
};

export { getApplicationDataAsync, getCurrentLanguageAsync };
