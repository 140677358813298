import React from 'react';
import { useTranslation } from 'react-i18next';

import { Generated } from '../Generated';

import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import Card from '../../../../components/Card';

export const CreateUser = ({data, onChangeData, onSubmitData, copy}) => {
    const { t } = useTranslation();
    return (
        <Card className="min-h-18 pa3">
            <div className="overflow-auto">
                <h4 className="f4 mt0 mb3">
                    {t('integration.title')}
                </h4>
                <div className="mv3">
                    <Input name="journeys" label={t('integration.journeys')} value={data["journeys"]} onChange={(evt) => { onChangeData({ "journeys": evt.target.value}); }} />
                </div>
                <div className="mv3">
                    <Input name="notification_namespace" label={t('integration.notification_namespace')} value={data["notification_namespace"]} onChange={(evt) => { onChangeData({ "notification_namespace": evt.target.value}); }} />
                </div>
                <div className="mv3">
                    <Input name="notification_return_to_flow" label={t('integration.notification_return_to_flow')} value={data["notification_return_to_flow"]} onChange={(evt) => { onChangeData({ "notification_return_to_flow": evt.target.value}); }} />
                </div>
                <div className="mv3">
                    <Input name="notification_return_to_state" label={t('integration.notification_return_to_state')} value={data["notification_return_to_state"]} onChange={(evt) => { onChangeData({ "notification_return_to_state": evt.target.value}); }} />
                </div>

                <div className="mt3">
                    <Button onClick={() => onSubmitData(data)}>{t('integration.submit')}</Button>
                </div>
                <div className="w-100 mt4">
                    <Generated data={data} copy={copy} />
                </div>
            </div>
        </Card>
    );
};

CreateUser.propTypes = {
};

export default CreateUser;
