import axios from "axios";
import api from '../factory/api';

export class SetupServiceError extends Error {
    constructor(message) {
        super(message);
        this.name = "ValidationError";
    }
}
export class SetupService {
    http;

    constructor(
        BOT_CONTRACT,
        BOT_KEY
    ) {
        this.http = axios.create({
            baseURL: './api/',
            headers: { 
                'takeblip-tenant-id': BOT_CONTRACT,
                'takeblip-bot-key': BOT_KEY
            }
        });
    }

    async update(oldConfig, newConfig) {
        return this.http.post('/setup/', {oldConfig, newConfig}).then(resp => resp.data).catch(err => { throw new SetupServiceError(err.message); });
    }
}

const getPokemonAsync = async (id) => {
    try {
        const { data } = await api.get(`/pokemon/${id}`);
        return data;
    } catch (err) {
        return false;
    }
};

export { getPokemonAsync };
