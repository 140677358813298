const translations = {
    header: {
        home: 'Dribble',
    },
    button: {
        documentation: 'Visita la documentación'
    },
    system: {
        title: 'Información del sistema',
        tenant: 'Nombre del contrato',
        name: 'Nombre del Bot',
        identifier: 'Identificador de Bot',
        accessKey: 'Llave de acceso',
    },
    integration: {
        title: 'Información de integración',
        ...JSON.parse('{"journeys":"Jornada(s)","notification_namespace":"Namespace","notification_return_to_flow":"Flow ID","notification_return_to_state":"State ID","generated":{}}'),
        submit: 'Actualizar',
        generated: {
            title: 'Información generada',
            ...JSON.parse('{}'),
            default: "Nada que ver aquí hasta ahora",
        }
    }
};

export default translations;