const translations = {
    header: {
        home: 'Dribble',
    },
    button: {
        documentation: 'See the docs'
    },
    system: {
        title: 'System Info',
        tenant: 'Contract Name',
        name: 'Bot Name',
        identifier: 'Bot Identifier',
        accessKey: 'Access Key',
    },
    integration: {
        title: 'Integration information',
        ...JSON.parse('{"journeys":"Journey(s)","notification_namespace":"Namespace","notification_return_to_flow":"Flow ID","notification_return_to_state":"State ID","generated":{}}'),
        submit: 'Update',
        generated: {
            title: 'Generated information',
            ...JSON.parse('{}'),
            default: 'Nothing to see here for now'
        }

    }
};

export default translations;