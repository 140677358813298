import axios from "axios";
import { v4 as uuid } from 'uuid';

export class BlipService {
    http;

    constructor(
        BOT_CONTRACT,
        BOT_KEY
    ) {
        const BOT_COMMANDS = `https://${BOT_CONTRACT}.http.msging.net/commands`;
        this.http = axios.create({
            baseURL: BOT_COMMANDS,
            headers: { 'Authorization': BOT_KEY }
        });
        this.http.interceptors.response.use(resp => {
            const { data } = resp;
            if (data.status === 'failure') {
                const reason = data.reason || { code: -1, description: 'Error not identied' };
                return Promise.reject(new Error(`Failed to process Blip-Resquest(${reason.code}) error: ${reason.description}`));
            }
            return resp;
        }, error => Promise.reject(error));
    }

    async createEvent(category, action) {
        const body = {
            id: uuid(),
            to: "postmaster@analytics.msging.net",
            method: "set",
            type: "application/vnd.iris.eventTrack+json",
            uri: "/event-track",
            resource: { category, action }
        };
        return this.http.post('', body).then(resp => resp.data);
    }

    async updateContact(contact, type) {
        const body = {
            id: uuid(),
            method: type,
            uri: "/contacts",
            type: "application/vnd.lime.contact+json",
            resource: { ...contact }
        };
        return this.http.post('', body).then(resp => resp.data);
    }

    async getBucket(id) {
        const body = {
            id: uuid(),
            method: "get",
            uri: `/buckets/${id}`
        };
        return this.http.post('', body).then(resp => resp.data.resource);
    }

    async updateBucket(id, resource) {
        const body = {
            id: uuid(),
            method: "set",
            uri: `/buckets/${id}`,
            type: "application/json",
            resource
        };
        return this.http.post('', body).then(resp => resp.data);
    }
}

export default BlipService;