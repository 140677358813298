const translations = {
    header: {
        home: 'Dribble',
    },
    button: {
        documentation: 'Visite a documentação'
    },
    system: {
        title: 'Informações do sistema',
        tenant: 'Nome do Contrato',
        name: 'Nome do Bot',
        identifier: 'Identificador do Bot',
        accessKey: 'Chave de acesso',
    },
    integration: {
        title: 'Informações de integração',
        ...JSON.parse('{"journeys":"Jornada(s)","notification_namespace":"Namespace","notification_return_to_flow":"ID do fluxo de retorno","notification_return_to_state":"ID do bloco de retorno","generated":{}}'),
        submit: 'Atualizar',
        generated: {
            title: 'Informações geradas',
            ...JSON.parse('{}'),
            default: "Nada para ver aqui até o momento"
        }
    }
};

export default translations;