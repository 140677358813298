const translations = {
    success: {
        loaded: 'Carregado corretamente',
        updated: 'Dados atualizados',
        copied: 'Copiado para a área de transferência'
    },
    error: {
        out: 'Fora do contexto TakeBlip',
        update: 'Erro ao atualizar',
        validation: 'Dados inválidos'
    },
    warning: {},
    info: {}
};

export default translations;
